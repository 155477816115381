<template>
  <div class="col-sm-12 bg-skyblue custom-round">
    <div class="row" style="position:absolute;z-index:1;width:100%">
      <div class="col-sm-4 offset-sm-8 text-right pt-2">
        <button
          class="btn btn-outline-secondary py-1 px-3
            custom-rounded-left border-right-0"
          @click="prevSlider"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g>
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
        <button
          class="btn btn-outline-secondary py-1 px-3 custom-rounded-right"
          @click="nextSlider"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g transform="translate(8 8) scale(-1 1) translate(-8 -8)">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <b-carousel
      id="carouselMedico"
      ref="carouselMedico"
      :interval="0"
      v-model="slide"
      background="transparent"
    >
      <div v-for="(medico, index) in medicos"
      v-bind:item="medico"
      v-bind:index="index"
      v-bind:key="index">
      <b-carousel-slide>
        <template #img>
          <div class="row bg-skyblue">
            <div class="col-sm-5 px-0 custom-round">
              <img
                :src="url + medico.logo_perfil"
                style="border-radius: 12px 0 0 12px;position: absolute"
                class="no-selectable"
                height="244px" width="100%" alt />
                <img
                :src="url + medico.logo_perfil"
                style="border-radius: 12px 0 0 12px;position: relative;"
                class="no-selectable"
                height="244px" width="100%" alt />
            </div>
            <div class="col-sm-7">
              <div class="row"><div class="col-sm-12 pt-3 pb-4"></div></div>
              <div class="row">
                <div class="col-sm-9 text-right">
                  <input type="hidden" v-model="posicion">
                  <h2 class="text-white"> {{medico.nombre}} </h2>
                </div>
              </div>
              <div>
                <div class="row mb-2">
                  <div class="col-sm-8 offset-sm-3 pr-0">
                    <b-card no-body class="py-2 pl-3">
                      <div class="col-sm-12 left-line-primary">
                        <div class="row">
                        <button
                          class="btn btn-outline-secondary
                          custom-rounded-left border-right-0"
                          @click="prevFecha(medico)"
                        >
                          <svg
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            focusable="false"
                            role="img"
                            aria-label="chevron left"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi-chevron-left b-icon bi"
                          >
                            <g transform="translate(0 -0.5)">
                              <g>
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                                </path>
                              </g>
                            </g>
                          </svg>
                        </button>
                        <div class="col-sm-7 d-flex justify-content-center">
                        <h2 class="text-center">
                          {{ dia }} {{ mes }}
                        </h2>
                        </div>
                        <button
                          class="btn btn-outline-secondary my-0
                          float-right"
                          @click="nextFecha(medico)"
                        >
                          <svg
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            focusable="false"
                            role="img"
                            aria-label="chevron left"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi-chevron-left b-icon bi"
                          >
                            <g transform="translate(0 -0.5)">
                              <g transform="translate(8 8) scale(-1 1) translate(-8 -8)">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                                </path>
                              </g>
                            </g>
                          </svg>
                        </button>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-sm-8 offset-sm-3 pr-0">
                    <b-card no-body class="py-2 pl-3">
                      <div class="col-sm-12 left-line-success">
                        <!-- <div class="align-middle spinner-bubble spinner-bubble-danger">
                          </div> -->
                        <span v-if="!turnos" class="text-light">Sin Trabajo</span>
                        <span v-else class="text-light">{{ cantidadCitas }}
                          Paciente Programado</span>
                      </div>
                    </b-card>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="text-right">
                      <button :class=" (cantidadCitas > 0 ) ? 'btn btn-success' : 'btn btn-warning'"
                        @click="pdfResumenDelDia(medico)" >Resumen del dia</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-carousel-slide>
      </div>
    </b-carousel>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  name: 'BannerMedico',
  components: {
  },
  data() {
    return {
      slide: 0,
      porPagina: 1,
      paginaActual: 1,
      medicos: [],
      url: process.env.VUE_APP_BASE,
      load: false,
      fecha: moment().locale('es'),
      dia: parseInt(moment().format('DD'), 10),
      mes: moment().locale('es').format('MMMM'),
      posicion: 0,
      total: 0,
      turnos: null,
      cantidadCitas: 0,
    };
  },
  created() {
    this.getMedicos();
  },
  methods: {
    prevSlider() {
      this.$refs.carouselMedico.prev();
      // eslint-disable-next-line operator-assignment
      this.posicion -= 1;
      this.posicion = this.posicion === -1 ? this.total - 1 : this.posicion;
      this.getTurno(this.medicos[this.posicion].id);
    },
    nextSlider() {
      this.$refs.carouselMedico.next();
      // eslint-disable-next-line operator-assignment
      this.posicion += 1;
      this.posicion = this.posicion < this.total ? this.posicion : 0;
      this.getTurno(this.medicos[this.posicion].id);
    },
    async getMedicos() {
      try {
        // this.porPagina = this.porPagina <= 0 ? 1 : this.porPagina;
        // const REQUEST = {
        //   page: this.paginaActual,
        //   perPage: this.porPagina,
        // };
        const RESPONSE = await axios.get('clinic/medicos/calendar');
        this.medicos = RESPONSE.data.data.data;
        this.total = this.medicos.length;
        this.getTurno(this.medicos[0].id);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.load = true;
      }
    },
    nextFecha(medico) {
      console.log(medico.id);
      const endDate = moment(this.fecha).add(1, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      this.dia = endDateDay;
      this.mes = moment(endDate).locale('es').format('MMMM');
      this.fecha = endDate;
      this.getTurno(medico.id);
    },
    prevFecha(medico) {
      console.log('medico', medico.id);
      const endDate = moment(this.fecha).subtract(1, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      this.dia = endDateDay;
      this.mes = moment(endDate).locale('es').format('MMMM');
      this.fecha = endDate;
      this.getTurno(medico.id);
    },
    async getTurno(medicoId) {
      this.turnos = null;
      try {
        const REQUEST = {
          fecha: moment(this.fecha).format('YYYY-MM-DD'),
          medico_id: medicoId,
        };
        // this.turnos = null;
        const RESPONSE = await axios.get('clinic/medicos/turno', { params: REQUEST });
        const DATATURNO = RESPONSE.data.data.turnos;
        this.verificarTurno(DATATURNO);
        this.cantidadCitas = RESPONSE.data.data.cantidad_citas;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    verificarTurno(turnos) {
      turnos.forEach((item) => {
        if (item.entrada) {
          this.turnos = item;
        }
      });
    },
    async pdfResumenDelDia(medico) {
      try {
        const YEAR = moment().locale('es').format('YYYY');
        const DIA = moment().locale('es').format('dddd');
        const FECHALITERAL = `${DIA} ${this.dia} de ${this.mes} ${YEAR}`;
        const REQUEST = {
          fecha: moment(this.fecha).format('YYYY-MM-DD'),
          medico_id: medico.id,
          fecha_literal: FECHALITERAL,
        };
        const RESPONSE = await axios.get('clinic/medico/resumen-dia', { params: REQUEST, responseType: 'blob' });
        // console.log(RESPONSE);
        const blob = new Blob([RESPONSE.data], { type: 'application/pdf' });
        const downloadUrl = URL.createObjectURL(blob);
        window.open(downloadUrl, '_blank');
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
  },
};
</script>

<style scoped>
.patient-scroll {
  height: 125px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.patient-scroll::-webkit-scrollbar {
  display: none;
}
.no-selectable {
  -webkit-user-drag: none;
}
.bg-skyblue
{
  background: #5ac8fb !important;
}
.custom-round {
  border-radius: 12px;
}
.custom-rounded-left{
  border-radius: 0.25rem 0 0 0.25rem;
}
.custom-rounded-right{
  border-radius: 0 0.25rem 0.25rem 0;
}
.left-line-primary {
  border-left: 3px solid #be75eb !important;
}
.left-line-success {
  border-left: 3px solid #9de3db !important;
}
</style>
