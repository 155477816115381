<template>
  <div class="row">
    <div class="col-sm-8">
      <div class="row mb-4">
        <BannerMedico />
      </div>
      <div class="col-sm-12 px-0">
        <Calendar
        @citaSemanal="citaSemanal"/>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="row pl-4">
        <div class="col-sm-12">
          <div class="row mb-3">
            <div class="col-sm-12 text-center">
              <button
                class="btn btn-skyblue col-sm-12 pt-3 rounded"
                @click="openModalCitas"
              >
                <h3 class="text-white">Crear Nueva Cita</h3>
              </button>
              <ModalProgramarCita />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-sm-12">
              <div class="search-bar">
                <input type="text" placeholder="Buscar Paciente/Medico" />
                <i class="search-icon text-muted i-Magnifi-Glass1"></i>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-card no-body class="py-3 px-3">
                <div class="row border-bottom border-light pb-2">
                  <div class="col-sm-6">
                    <button class="btn btn-success col-sm-11">Pagadas</button>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button class="btn btn-light col-sm-11">Pendientes</button>
                  </div>
                </div>
                <div class="row pt-3 px-3">
                  <div class="col-sm-8">
                    <label class="h5">Citas del Dia</label>
                  </div>
                  <div class="col-sm-4 text-skyblue">
                    <label class="h5" style="cursor: pointer">Ver Todo</label>
                  </div>
                </div>
                <div class="row pl-3 pr-1">
                  <div class="col-sm-12 custom-scroll">
                    <div class="row pr-2">
                      <div class="col-sm-12 text-light my-3 pl-0">
                        <!-- Lunes, 8 de agosto de 2020 -->
                        {{ getActualRangeWeek }}
                      </div>
                      <b-card v-for="(medico) in medicosActivos" :key="medico.medico_id"
                        no-body class="col-sm-12 mt-3 py-3"
                        v-bind:style="{ background: medico.color }">
                        <div class="row py-3">
                          <div class="col-sm-2 pl-2 pr-0">
                            <b-avatar :src="url + medico.logo_perfil">
                            </b-avatar>
                          </div>
                          <div class="col-sm-10 pl-0" style="color: white">
                            {{ medico.medico }}<br>
                            {{ medico.horario }}<br>
                          </div>
                        </div>
                        <template v-for="(cita) in citasDiarias">
                          <div class="row pb-3" style="color: white"
                          v-if="cita.medico_id === medico.medico_id"
                          :key="cita.venta_detalle_id">
                            <div
                            class="col-sm-10 text-right pl-0">
                              {{ cita.paciente }} <br>
                              {{ cita.servicios }}
                            </div>
                            <div class="col-sm-2 px-1">
                              <b-avatar :src="url">
                              </b-avatar>
                            </div>
                          </div>
                        </template>
                      </b-card>
                      <b-card v-if="medicosActivos.length === 0"
                        no-body class="col-sm-12 mt-3 py-3 bg-card-color-2">
                        No tiene citas pendientes.
                      </b-card>
                      <img v-if="medicosActivos.length === 0"
                        :src="require('@/assets/images/faces/descanso.jpg')"
                        style="border-radius: 12px 0 0 12px;position: relative;"
                        class="no-selectable"
                        height="244px" width="80%" alt />
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import BannerMedico from '../components/TheBannerMedico.vue';
import Calendar from '../components/TheCalendar.vue';
import ModalProgramarCita from '../components/TheModalProgramarCita.vue';

export default {
  name: 'index',
  components: {
    BannerMedico,
    Calendar,
    ModalProgramarCita,
  },
  created() {
    this.citasDelDia();
  },
  data() {
    return {
      fechaInicio: moment(),
      fechaFinal: moment(),
      medicos: [],
      citasDiarias: [],
      medicosActivos: [],
      getActualRangeWeek: null,
      url: process.env.VUE_APP_BASE,
    };
  },
  methods: {
    openModalCitas() {
      this.$bvModal.show('modal-programarCita');
    },
    citasDelDia() {
      this.medicos = [];
      this.citasDiarias = [];
      this.medicosActivos = [];
      const data = {
        fecha_inicio: moment(this.fechaInicio).format('YYYY-MM-DD'),
        fecha_fin: moment(this.fechaFinal).format('YYYY-MM-DD'),
      };
      axios.get('/clinic/turnos-programados', { params: data })
        .then((response) => {
          this.medicos = response.data.data.citas_programadas.medico_turnos;
          this.citasDiarias = response.data.data.citas_programadas.citas_dias;
          this.medicos.forEach((ele) => {
            this.citasDiarias.forEach((item) => {
              if (ele.medico_id === item.medico_id) {
                const index = this.medicosActivos.findIndex((e) => (e.medico_id === ele.medico_id));
                if (index < 0) {
                  this.medicosActivos.push(ele);
                }
              }
            });
          });
        })
        .catch((error) => {
          util.showNotify(error.response.data.message, 'error');
        });
    },
    citaSemanal(getActualRangeWeek, fechaInicio, fechaFinal) {
      this.fechaInicio = fechaInicio;
      this.fechaFinal = fechaFinal;
      this.getActualRangeWeek = getActualRangeWeek;
      if (fechaInicio === 'Invalid date' || fechaInicio === null
        || fechaFinal === 'Invalid date' || fechaFinal === null) {
        this.fechaInicio = moment();
        this.fechaFinal = moment();
        this.getActualRangeWeek = this.getActualRangeWeeks(this.fechaInicio);
      }
      this.citasDelDia();
    },
    getActualRangeWeeks(fechaInicio) {
      const initDay = moment(fechaInicio).format('DD');
      const initMonth = moment(fechaInicio).locale('es').format('MMMM');
      const initYear = moment(fechaInicio).format('YYYY');
      const endDate = moment(fechaInicio).add(6, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      const endDateMonth = moment(endDate).format('MMMM');
      const endDateYear = moment(endDate).format('YYYY');
      if (fechaInicio !== null) {
        if (initYear === endDateYear) {
          return `${initDay} - ${endDateDay} ${initMonth} ${initYear}`;
        }
        return `${initDay} ${initMonth} ${initYear} - ${endDateDay} ${endDateMonth} ${endDateYear}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>
.bg-card-color-1 {
  background: #d3cbfa;
}
.bg-card-color-2 {
  background: #ffd3d4;
}
.bg-card-color-3 {
  background: #bfeff3;
}
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:active {
  box-shadow: 0 0 0 0.2rem rgba(67, 179, 228, 0.5) !important;
  background-color: rgb(52, 143, 212);
}
.btn-skyblue:focus {
  box-shadow: 0 8px 25px -8px rgba(67, 179, 228, 0.6);
}
.text-skyblue
{
  color: #61c6f2;
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #fff ;;
  border: 1px solid gray;
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 40px;
}
.search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}
.search-bar.search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}
.custom-scroll {
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #6f55dd #f0f0f0;
}
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: #6f55dd;
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 10px;
}
</style>
