<template>
  <div class="col-sm-12 px-0">
    <div class="row py-2">
      <div class="col-sm-4">
        <label class="h4">Citas Programadas</label>
      </div>
      <div class="col-sm-4 text-right">
        <label class="col-form-label">{{getActualRangeWeek}}</label>
      </div>
      <div class="col-sm-2 px-0">
        <date-picker
          id="filtroFecha"
          v-model="datepicker"
          class="col-sm-12 px-0"
          lang="es"
          type="week"
          placeholder="Semana del año"
        ></date-picker>
      </div>
      <div class="col-sm-2 text-right pl-0">
        <button
          class="btn btn-outline-secondary py-1 px-3
            custom-rounded-left border-right-0"
            @click="retrocederDia"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g>
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
        <button
          class="btn btn-outline-secondary py-1 px-3 custom-rounded-right"
          @click="avanzarDia"
        >
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="chevron left"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-chevron-left b-icon bi"
          >
            <g transform="translate(0 -0.5)">
              <g transform="translate(8 8) scale(-1 1) translate(-8 -8)">
                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0
                  .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5
                  0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z">
                </path>
              </g>
            </g>
          </svg>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 px-0">
        <b-card class="pt-3 pb-1" no-body>
          <div
            style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            class="border-bottom border-light"
          >
            <div></div>
            <div v-for="(item, index) in diasCabecera" :key="index"
              class="text-center"
              :class="item.active? 'text-primary':''"
            >
              <label class="h4 mb-0">{{item.nombre}}</label><br>
              <label class="h4 font-weight-bold">{{item.diaMes}}</label>
            </div>
          </div>
          <div class="calendar-scroll">
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light"></div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">07:00<br>AM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-1 px-1 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center p-1 border-light border-right border-bottom"
              >
                <b-card class="bg-card-color-3 py-2 text-left" no-body>
                  <span class="pb-2">Development Health Website</span>
                  07:00-07:30 AM
                </b-card>
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">08:00<br>AM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center p-1 border-light border-right border-bottom"
              >
                <b-card class="bg-card-color-1 py-2 text-left" no-body>
                  <span class="pb-2">Project Design Meeting</span>
                  08:00-09:00 AM
                </b-card>
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center p-1 border-light border-right border-bottom"
              >
                <b-card class="bg-card-color-3 py-2 text-left" no-body>
                  <span class="pb-2">New Project Design Meeting</span>
                  08:00-09:00 AM
                </b-card>
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">09:00<br>AM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center p-1 border-light border-right border-bottom"
              >
                <b-card class="bg-card-color-2 py-2 text-left" no-body>
                  <span class="pb-2">Doctor Website Client Call</span>
                  09:00-10:00 AM
                </b-card>
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">10:00<br>AM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center p-1 border-light border-right border-bottom"
              >
                <b-card class="bg-card-color-2 py-2 text-left" no-body>
                  <span class="pb-2">Architect App Client Call</span>
                  10:00-10:15 AM
                </b-card>
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center p-1 border-light border-right border-bottom"
              >
                <b-card class="bg-card-color-1 py-2 text-left" no-body>
                  <span class="pb-2">Project Design Meeting</span>
                  10:00-10:15 AM
                </b-card>
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">11:00<br>AM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">12:00<br>PM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
            <div
              style="display:grid;grid-template-columns: 0.7fr repeat(7,1fr);"
            >
              <div class="text-light pl-2">13:00<br>PM</div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-right border-bottom"
              >
              </div>
              <div
                class="text-center py-5 border-light border-bottom"
              >
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

export default {
  name: 'Calendar',
  components: {
    DatePicker,
  },
  data() {
    return {
      datepicker: null,
      diasCabecera: [
        {
          nombre: 'DOM.',
          diaMes: '7',
          active: false,
        },
        {
          nombre: 'LUN.',
          diaMes: '8',
          active: true,
        },
        {
          nombre: 'MAR.',
          diaMes: '9',
          active: false,
        },
        {
          nombre: 'MIÉ.',
          diaMes: '10',
          active: false,
        },
        {
          nombre: 'JUE.',
          diaMes: '11',
          active: false,
        },
        {
          nombre: 'VIE.',
          diaMes: '12',
          active: false,
        },
        {
          nombre: 'SÁB.',
          diaMes: '13',
          active: false,
        },
      ],
      fechaInicio: null,
      fechaFinal: null,
    };
  },
  methods: {
    avanzarDia() {},
    retrocederDia() {},
  },
  computed: {
    getActualRangeWeek() {
      const initDay = moment(this.datepicker).format('DD');
      const initMonth = moment(this.datepicker).locale('es').format('MMMM');
      const initYear = moment(this.datepicker).format('YYYY');
      const endDate = moment(this.datepicker).add(6, 'days').toDate();
      const endDateDay = moment(endDate).format('DD');
      const endDateMonth = moment(endDate).locale('es').format('MMMM');
      const endDateYear = moment(endDate).format('YYYY');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.fechaInicio = moment(this.datepicker).format('YYYY-MM-DD');
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.fechaFinal = moment(this.datepicker).add(6, 'days').format('YYYY-MM-DD');
      if (this.datepicker !== null) {
        if (initYear === endDateYear) {
          return `${initDay} - ${endDateDay} ${initMonth} ${initYear}`;
        }
        return `${initDay} ${initMonth} ${initYear} - ${endDateDay} ${endDateMonth} ${endDateYear}`;
      }
      return '';
    },
  },
  watch: {
    getActualRangeWeek() {
      if (this.fechaInicio !== 'Invalid Date' || this.fechaInicio !== ''
        || this.fechaFinal !== 'Invalid Date' || this.fechaFinal !== '') {
        this.$emit('citaSemanal', this.getActualRangeWeek, this.fechaInicio, this.fechaFinal);
      }
    },
  },
};
</script>

<style scoped>
.custom-rounded-left{
  border-radius: 0.25rem 0 0 0.25rem;
}
.custom-rounded-right{
  border-radius: 0 0.25rem 0.25rem 0;
}
.calendar-scroll {
  height: 658px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.calendar-scroll::-webkit-scrollbar {
  display: none;
}
.bg-card-color-1 {
  background: #d3cbfa;
}
.bg-card-color-2 {
  background: #ffd3d4;
}
.bg-card-color-3 {
  background: #bfeff3;
}
</style>
