<template>
  <b-modal id="modal-programarCita"
    size="lg"
    title="Programar una Cita"
    hide-footer
  >
    <div class="row mb-3">
      <div class="col-sm-6 pr-0">
        <div class="form-group">
          <label>Paciente:</label>
          <multiselect
            v-model="pacienteSelected"
            :options="listaPacientes"
            placeholder=""
            label="full_name" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(pacienteSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.full_name}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.full_name}}</small>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-1">
        <div class="form-group">
          <label style="user-select: none"><br></label>
          <button
            class="btn btn-success pr-3"
            @click="openModalPaciente"
          >
            +
          </button>
          <ModalPacienteNuevo @nuevoPaciente="pacienteSelected = $event"/>
        </div>
      </div>
      <div class="col-sm-3 offset-sm-2">
        <div class="form-group">
          <label>Fecha:</label>
          <date-picker
            id="inputFecha"
            class="col-sm-11 pl-0"
            v-model="datepicker"
            lang="es"
            format="DD/MM/YYYY"
            type="date"
            value-type="date"
          ></date-picker>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-sm-3 offset-sm-4 pr-0 text-right">
        <div class="form-group">
          <label style="user-select: none"><br></label>
          <div>
            <button
              class="btn btn-success col-sm-10"
              @click="openModalServicios"
            >Agregar Servicio</button>
            <ModalServicios />
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-2 offset-sm-3 pr-0">
        <button class="btn btn-success col-sm-12">Turno 1</button>
      </div>
      <div class="col-sm-2 pr-0">
        <button class="btn btn-warning col-sm-12">Turno 2</button>
      </div>
      <div class="col-sm-3">
        <button class="btn btn-warning">Turno Eventual</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 pr-0">
        <div class="form-group">
          <label>Medico:</label>
          <multiselect
            v-model="medicoSelected"
            :options="listaMedicos"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(medicoSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
            <template slot="singleLabel" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
            <template slot="option" slot-scope="props">
              <small>{{props.option.nombre}}</small>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-3 pr-0">
        <div class="form-group">
          <label>Horario:</label>
          <input type="text" class="form-control">
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-group">
          <label>Piso/Sala:</label>
          <input type="text" class="form-control col-sm-10">
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';
import ModalPacienteNuevo from './TheModalPacienteNuevo.vue';
import ModalServicios from './TheModalServicios.vue';

export default {
  name: 'ModalProgramarCita',
  components: {
    Multiselect,
    DatePicker,
    ModalPacienteNuevo,
    ModalServicios,
  },
  data() {
    return {
      pacienteSelected: null,
      listaPacientes: [],
      datepicker: new Date(),
      medicoSelected: null,
      listaMedicos: [],
    };
  },
  methods: {
    openModalPaciente() {
      this.$bvModal.show('modal-pacienteNuevo');
    },
    openModalServicios() {
      this.$bvModal.show('modal-servicios');
    },
  },
};
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
