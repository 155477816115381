<template>
  <b-modal id="modal-servicios"
    size="lg"
    title="Agregar Servicio"
  >
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="form-group mb-0">
          <label class="col-form-label">Grupo de Servicio:</label>
          <multiselect id="comboCategoriaServicios"
            v-model="categoriaServiciosSelected"
            :options="categoriaServicios"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
            <span slot="caret" v-if="!(categoriaServiciosSelected===null)"></span>
            <span slot="noResult">Sin Resultados</span>
            <span slot="noOptions">Lista Vacia</span>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-6">
        <div class="form-group">
          <label class="col-form-label">Servicio:</label>
          <multiselect id="comboServicios"
            ref="comboServicios"
            v-model="serviciosSelected"
            :options="listaServiciosInteligente"
            :multiple="true"
            placeholder=""
            label="nombre" track-by="id"
            select-label=""
            deselect-label="X">
              <span slot="caret" v-if="!(serviciosSelected.length===0)"></span>
              <span slot="noResult">Sin Resultados</span>
              <span slot="noOptions">Lista Vacia</span>
              <template slot="option" slot-scope="props">
                <small>{{props.option.nombre}}</small>
              </template>
          </multiselect>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label class="col-form-label" style="user-select: none"><br></label>
          <div class="row mb-2">
            <div class="col-sm-4 text-right">
              <label class="col-form-label">
                Lista de Precios:
              </label>
            </div>
            <div class="col-sm-4 px-0">
              <multiselect id="comboPrecios"
                v-model="precioSelected"
                :options="precios"
                :allow-empty="false"
                placeholder="Seleccione Precio"
                label="descuento" track-by="id"
                select-label=""
                deselect-label="X">
                <span slot="caret" v-if="!(precioSelected===null)"></span>
                <span slot="noResult">Sin Resultados</span>
                <span slot="noOptions">Lista Vacia</span>
              </multiselect>
            </div>
            <div class="col-sm-4">
              <input id="inputPrecioSelectedFromCombo"
                type="text"
                class="form-control"
                :value="precioSelectedCombo | numeral('0,0.00')"
                autocomplete="off"
                disabled
              >
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 offset-sm-4 px-0">
              <div class="form-group">
                <label class="label-control">Cantidad</label>
                <input type="text" id="inputCantidad"
                  ref="inputCantidad"
                  v-model="cantidad"
                  class="form-control"
                  autocomplete="off"
                >
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <label class="label-control">Precio Manual</label>
                <vue-numeric class="form-control"
                  v-model="precioManual"
                  separator=","
                  :precision="2"
                  placeholder=" "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <button class="btn btn-outline-dark" @click="cancel()">
        Cancelar
      </button>
      <button class="btn btn-warning" @click="ok()">
        Agregar
      </button>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';

export default {
  name: 'ModalServicios',
  components: {
    Multiselect,
    VueNumeric,
  },
  data() {
    return {
      categoriaServiciosSelected: null,
      categoriaServicios: [],
      serviciosSelected: [],
      listaServiciosInteligente: [],
      precioSelected: null,
      precios: [],
      precioSelectedCombo: '',
      cantidad: 1,
      precioManual: 0,
    };
  },
};
</script>

<style scoped>
</style>
